import React, { useEffect } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux';
import EstimatorCarousel from './EstimatorCarousel';
import { 
    setSubmitEnabled, 
    setProgress, 
    setActiveSlideId,
    setNextButtonActive,
    setBackButtonActive,
    setCurrSlide,
} from '../../actions/estimator'
import { sendResults, updateResults } from '../../actions/session';
import './Style/style.css'
import Submission from './Submission2';
import TagManager from 'react-gtm-module'

function EstimatorContainer() {
    const dispatch = useDispatch()
    const questionInfo = useSelector((state) => state.questionInfo.questionInfo)
    const submitEnabled = useSelector((state) => state.estimator.submitEnabled)
    const currSlide = useSelector((state) => state.estimator.currSlide)
    const activeSlideId = useSelector((state) => state.estimator.activeSlideId)
    const answeredSlides = useSelector((state) => state.estimator.answeredSlides)
    const backButtonActive = useSelector((state) => state.estimator.backButtonActive)
    const nextButtonActive = useSelector((state) => state.estimator.nextButtonActive)
    const answers = useSelector((state) => state.answers)
    const session = useSelector((state) => state.session)
   

    const handleNext = () => {
        
        const currSlideId = document.getElementsByClassName('carousel-item active')[0].id

        const tagManagerArgs = {
            gtmId: 'GTM-53XGW7L',
            dataLayer: {
                event: 'pageview',
                page: `${currSlide} - ${questionInfo[currSlide].question_text}`,
                slideId: currSlideId,
            },
            dataLayerName: 'PageDataLayer'
        }
        TagManager.dataLayer(tagManagerArgs)

        if (currSlide === questionInfo.length - 1) {
            dispatch(setSubmitEnabled(true))
        }
        if (currSlide < questionInfo.length && (activeSlideId !== currSlideId || !activeSlideId)) {

            const newSlide = currSlide + 1
            setNewProgress(newSlide)
            dispatch(setActiveSlideId(document.getElementsByClassName('carousel-item active')[0].id))
            const nextForm = questionInfo.at(currSlide).question_form_id
            window.scrollTo({top: 0, behavior: 'smooth'})
            
            if (answeredSlides.includes(nextForm)||(questionInfo[currSlide].question_type === "checkbox" && !questionInfo[currSlide].required_flag)) {
                dispatch(setNextButtonActive(true))
            } else {
                dispatch(setNextButtonActive(false))
            }
        } 
        let final = {...answers, ...session}
        if (currSlide === 1) dispatch(sendResults(final))
        else dispatch(updateResults(final))
    }

    const handleBack = () => {
        if (submitEnabled) dispatch(setSubmitEnabled(false))
        const currSlideId = document.getElementsByClassName('carousel-item active')[0].id
        const newSlide = currSlide - 1
        if (currSlide > 1 && (activeSlideId !== currSlideId || !activeSlideId)) {
            setNewProgress(newSlide)
            dispatch(setActiveSlideId(document.getElementsByClassName('carousel-item active')[0].id))
        }
        dispatch(setNextButtonActive(true))
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    const setNewProgress = newSlide => {
        const newProgress = Math.round(newSlide/questionInfo.length * 100)
        dispatch(setCurrSlide(newSlide))
        dispatch(setProgress(newProgress))
        
        if (newSlide !== 1) dispatch(setBackButtonActive(true))
        else dispatch(setBackButtonActive(false))
    }

    return (
        <div className="container">
            <div class="agile-estimator">
                {submitEnabled
                ? <Submission />
                : <>
                    {/* <div class="estimator-note">
                        <p class="light-blue-text">Augusto Digital’s Agile Estimator is a web and mobile app development cost calculator that will provide you with an estimated price for your next project.</p>
                    </div> */}
                    <EstimatorCarousel />

                    <div className="estimator-pagination">
                        {backButtonActive &&
                            <div className="estimator-pagination-previous" >
                            {backButtonActive
                                ? <a 
                                    href ="#" 
                                    className="button"
                                    data-bs-target="#questionCarousel" 
                                    data-bs-slide="prev"
                                    onClick={e => handleBack(e)}
                                >
                                    <svg className="button-icon button-icon-previous" xmlns="http://www.w3.org/2000/svg" width="15.524" height="17.065" viewBox="0 0 15.524 17.065"><path id="icons8-chevron_1_" data-name="icons8-chevron (1)" d="M11.532,4,3,9.688V13.48l8.532-5.688,8.532,5.688V9.688Zm0,6.044L3,15.732v3.792l8.532-5.688,8.532,5.688V15.732Z" transform="translate(-4 20.065) rotate(-90)" fill="#ccc"/></svg>
                                    <span>PREVIOUS</span>
                                </a>
                                : <a 
                                    href ="#" 
                                    className="button button-grey"
                                    data-bs-target="#questionCarousel" 
                                >
                                    <svg className="button-icon button-icon-previous" xmlns="http://www.w3.org/2000/svg" width="15.524" height="17.065" viewBox="0 0 15.524 17.065"><path id="icons8-chevron_1_" data-name="icons8-chevron (1)" d="M11.532,4,3,9.688V13.48l8.532-5.688,8.532,5.688V9.688Zm0,6.044L3,15.732v3.792l8.532-5.688,8.532,5.688V15.732Z" transform="translate(-4 20.065) rotate(-90)" fill="#ccc"/></svg>
                                    <span>PREVIOUS</span>
                                </a>
                            }
                            
                        </div>
                        
                        }
                        <div className="estimator-pagination-next">
                            {nextButtonActive 
                                ? <a 
                                    href ="#" 
                                    className="button"
                                    data-bs-target="#questionCarousel" 
                                    data-bs-slide="next"
                                    onClick={() => handleNext()}
                                >
                                    <span>NEXT</span>
                                    <svg className="button-icon button-icon-next" xmlns="http://www.w3.org/2000/svg" viewBox="0 -32 426.6667 426"><path d="M213.332 181.668c0 4.2656-1.2773 8.5312-3.625 11.7304l-106.668 160C99.1993 359.1602 92.8009 363 85.332 363h-64C9.6016 363 0 353.3984 0 341.668c0-4.2696 1.2813-8.5352 3.625-11.7344l98.7734-148.2656L3.625 33.3984C1.2812 30.1992 0 25.9336 0 21.668 0 9.9336 9.6016.332 21.332.332h64c7.4688 0 13.8672 3.8399 17.707 9.6016l106.668 160c2.3477 3.1992 3.625 7.4648 3.625 11.7344zM426.668 181.668c0 4.2656-1.2813 8.5312-3.629 11.7304l-106.664 160C312.5352 359.1602 306.1328 363 298.668 363h-64c-11.7344 0-21.336-9.6016-21.336-21.332 0-4.2696 1.2813-8.5352 3.629-11.7344l98.7734-148.2656-98.7735-148.2696c-2.3476-3.1992-3.6289-7.4648-3.6289-11.7304 0-11.7344 9.6016-21.336 21.336-21.336h64c7.4648 0 13.8672 3.8399 17.707 9.6016l106.664 160c2.3477 3.1992 3.629 7.4648 3.629 11.7344zm0 0"/></svg>
                                </a>
                                : <a 
                                    href ="#" 
                                    className="button button-grey" 
                                    data-bs-target="#questionCarousel" 
                                >
                                    <span>NEXT</span>
                                    <svg className="button-icon button-icon-next" xmlns="http://www.w3.org/2000/svg" viewBox="0 -32 426.6667 426"><path d="M213.332 181.668c0 4.2656-1.2773 8.5312-3.625 11.7304l-106.668 160C99.1993 359.1602 92.8009 363 85.332 363h-64C9.6016 363 0 353.3984 0 341.668c0-4.2696 1.2813-8.5352 3.625-11.7344l98.7734-148.2656L3.625 33.3984C1.2812 30.1992 0 25.9336 0 21.668 0 9.9336 9.6016.332 21.332.332h64c7.4688 0 13.8672 3.8399 17.707 9.6016l106.668 160c2.3477 3.1992 3.625 7.4648 3.625 11.7344zM426.668 181.668c0 4.2656-1.2813 8.5312-3.629 11.7304l-106.664 160C312.5352 359.1602 306.1328 363 298.668 363h-64c-11.7344 0-21.336-9.6016-21.336-21.332 0-4.2696 1.2813-8.5352 3.629-11.7344l98.7734-148.2656-98.7735-148.2696c-2.3476-3.1992-3.6289-7.4648-3.6289-11.7304 0-11.7344 9.6016-21.336 21.336-21.336h64c7.4648 0 13.8672 3.8399 17.707 9.6016l106.664 160c2.3477 3.1992 3.629 7.4648 3.629 11.7344zm0 0"/></svg>
                                </a>
                            }
                        </div>
                    </div>
                </>
                }
            </div>
        </div>
    )
}

export default connect()(EstimatorContainer);