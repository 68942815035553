import React, { useEffect }  from 'react'
import { connect, useDispatch, useSelector } from 'react-redux' 
import { setSessionEmail, setSessionFirstName, setSessionLastName, setSessionPhone } from '../../actions/session'
import "./Style/style.css"
import {
    setSubmitCompleted,
    setFirstNameError,
    setLastNameError,
    setEmailError,
    setPhoneError,
} from '../../actions/estimator'
import { validateEmail, validatePhone } from '../../utils/validations';
import { updateResults } from '../../actions/session';
import Confirmation from './Confirmation';
import HubspotForm from 'react-hubspot-form'



function Submission() {
    const dispatch = useDispatch()
    const firstNameError = useSelector((state) => state.estimator.firstNameError)
    const lastNameError = useSelector((state) => state.estimator.lastNameError)
    const phoneError = useSelector((state) => state.estimator.phoneError)
    const emailError = useSelector((state) => state.estimator.emailError)
    const answers = useSelector((state) => state.answers)
    const session = useSelector((state) => state.session)
    const questionInfo = useSelector((state) => state.questionInfo.questionInfo)
    const submitCompleted = useSelector((state) => state.estimator.submitCompleted)
    const sessionEmail = session.sessionEmail
    const sessionFirstName = session.sessionFirstName
    const sessionLastName = session.sessionLastName
    const sessionPhone = session.sessionPhone


    const handleSubmit = (e) => {
        // !sessionFirstName ? dispatch(setFirstNameError(true)) : dispatch(setFirstNameError(false))
        // !sessionLastName ? dispatch(setLastNameError(true)) : dispatch(setLastNameError(false))
        // !validatePhone(sessionPhone) ? dispatch(setPhoneError(true)) : dispatch(setPhoneError(false))
        // !validateEmail(sessionEmail) ? dispatch(setEmailError(true)) : dispatch(setEmailError(false))
        const sessionData = {
            sessionId: session.sessionId,
            sessionBrowser: session.sessionBrowser,
            sessionBrowserVersion: session.sessionBrowserVersion,
            sessionIpAddress: session.sessionIpAddress,
            sessionFirstName: e[0].value,
            sessionLastName: e[1].value,
            sessionEmail: e[2].value,
            sessionPhone: e[3].value,
        }
        console.log(session)
        console.log(sessionData)
        let final = {...answers, ...sessionData}
        dispatch(updateResults(final))
        console.log("AFTER dispatch")
    }

    return (
        <>
            <div className="agile-estimator final-page"> 
                <div className="estimator-progress">
                    <div className="progress-count">
                        <div className="slide-number">
                            <h2>{questionInfo.length}</h2>
                            <h2 className="grey-text">/</h2>
                            <h2 className="grey-text">{questionInfo.length}</h2>
                        </div>
                    </div>
                </div>
                <p className="estimator-instruction">{questionInfo[questionInfo.length - 1].question_text}</p>
                <div className="slider-questions">
                    <HubspotForm
                        region='na1'
                        portalId='22539163'
                        formId='45b4d0f6-28e9-4b18-a9cf-5c5255b7fe46'
                        version='V2_PRERELEASE'
                        onReady={() => {
                            const script = document.createElement('script');
                            script.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js';
                            let jqueryScript = document.getElementsByTagName('script');
                            let src = Array.from(jqueryScript).filter(item => item.src === script.src)
                            if(src.length === 0) {
                            document.body.appendChild(script)
                            }
                        }}
                        onSubmit={e => handleSubmit(e)}
                    />
                </div>
            </div>
        </>
    )
}

export default connect()(Submission);
