import { 
    GET_SESSION_IP, 
    SET_SESSION_BROWSER, 
    SET_SESSION_EMAIL, 
    SET_SESSION_FIRST_NAME, 
    SET_SESSION_LAST_NAME, 
    SET_SESSION_PHONE
} from '../actions/types.js'
import { v4 as uuidv4 } from 'uuid';

export const initialState = {
    sessionId: uuidv4(),
    sessionBrowser: '',
    sessionBrowserVersion: '',
    sessionIpAddress: '',
    sessionFirstName: '',
    sessionLastName: '',
    sessionEmail: '',
    sessionPhone: '',
}

export default function(state = initialState, action) {
    switch(action.type) {
        case GET_SESSION_IP:
            return {
                ...state,
                sessionIpAddress: action.payload.IPv4
            }
        case SET_SESSION_BROWSER:
            return {
                ...state,
                sessionBrowser: action.payload.sessionBrowser,
                sessionBrowserVersion: action.payload.sessionBrowserVersion,
            }
        case SET_SESSION_FIRST_NAME:
            return {
                ...state,
                sessionFirstName: action.payload
            }
        case SET_SESSION_LAST_NAME:
            return {
                ...state,
                sessionLastName: action.payload
            }
        case SET_SESSION_EMAIL:
            return {
                ...state,
                sessionEmail: action.payload
            }
        case SET_SESSION_PHONE:
            return {
                ...state,
                sessionPhone: action.payload
            }
        default:
            return state
    }
}
