import React, { useState } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux';
import { 
    setAppCompliance,
    setAppSize,
    setFinishedDesign,
    setReportDashboard,
    setHostingEnvironment,
    setNewOrExisting,
    setExistingDb,
    setCloudSupport,
    setMarketingWebsite,
    setOngoingSupport,
} from '../../actions/answers'
import { setAnsweredSlides, setNextButtonActive } from '../../actions/estimator'
import "./Style/style.css"
import { getAnswerLogo } from "../assets/logos"


function Question(props) {
    const dispatch = useDispatch()
    const answeredSlides = useSelector((state) => state.estimator.answeredSlides)
    const [clicked, setClicked] = useState();

    const questionNum = props.index + 1
    const question = props.questionInfo

    
    const handleClick = (e, question, answer) => {
        let newAnsweredSlides = answeredSlides.slice()
        if (!newAnsweredSlides.includes(question.question_form_id))
            newAnsweredSlides.push(question.question_form_id)
        
        dispatch(setAnsweredSlides(newAnsweredSlides))
        dispatch(setNextButtonActive(true))
        
        let selectedOption
        let currSelectedOption
        let answers = question.answers
        answers.forEach((x, i) => {
            if (clicked === x.answer_text) { 
                selectedOption = x
            } else if (answer.answer_text === x.answer_text) {
                currSelectedOption = x
            }
        })

        switch ( question.question_form_id) {
            case 'uiDesignRadio':
                dispatch(setFinishedDesign(answer.answer_text))
                break;
            case 'appSizeRadio':
                dispatch(setAppSize(answer.answer_text))
                break;
            case 'existingAppRadio':
                dispatch(setNewOrExisting(answer.answer_text))
                break;
            case 'encryptionRadio':
                dispatch(setAppCompliance(answer.answer_text))
                break;
            case 'backendCloudSupportRadio':
                dispatch(setCloudSupport(answer.answer_text))
                break;
            case 'existingDbRadio':
                dispatch(setExistingDb(answer.answer_text))
                break;
            case 'reportsDashboardsRadio':
                dispatch(setReportDashboard(answer.answer_text))
                break;
            case 'marketPageRadio':
                dispatch(setMarketingWebsite(answer.answer_text))
                break;
            case 'hostingEnvironmentRadio':
                dispatch(setHostingEnvironment(answer.answer_text))
                break;
            case 'ongoingSupportRadio':
                dispatch(setOngoingSupport(answer.answer_text))
                break;
            default:
                break;
        }
        setClicked(answer.answer_text)
    }

    return (
        <div>
            <div className="estimator-progress">
                <div className="estimator-heading">
                    <div className="progress-count">
                        <div className="slide-number">
                            <h2>{('0'+questionNum).slice(-2)}</h2>
                            <h2 className="grey-text">/</h2>
                            <h2 className="grey-text">{props.count}</h2>
                        </div>
                        <h2 className="slide-title">{question.question_text}</h2>
                    </div>
                    <div>
                        <h6 className="slide-description">{question.question_description}</h6>
                    </div>
                </div>
                <p className="estimator-instruction light-blue-text">Select one.</p>
            </div>
            <div className="slider-questions">
                {question.answers.map((answer, index) => {
                    return (
                        <div className={"slide-question " + (clicked === answer.answer_text ? "question-selected" : "")} onClick={e => handleClick(e, question, answer)}>
                            <div className="slide-top">
                                {getAnswerLogo(question.question_form_id, answer.answer_text)}
                                <h3>{answer.answer_text}</h3>
                                <p>{answer.answer_description}</p>
                            </div>
                            {question.question_category === "project_estimate" && answer.display_estimate &&
                                <div className="slide-bottom"> 
                                    <div className={answer.value === 0 ? "slide-bottom-hidden" : ""}>
                                        <h6 className="slide-bottom-title">ESTIMATES</h6>
                                        <div className="slide-bottom-estimates">
                                            <div className="slide-bottom-estimate">
                                                <h6>INVESTMENT</h6>
                                                <h5>
                                                ${answer.cost_low} - ${answer.cost_high}
                                                </h5> 
                                            </div>
                                            <div className="slide-bottom-estimate">
                                                <h6>STORY POINTS</h6>
                                                <h5>{answer.value}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default connect()(Question);