import React from 'react'
import { connect } from 'react-redux';

function Confirmation() {
   
    const queryParam = window.location.search;
    const params = new URLSearchParams(queryParam);
    const returnUrl = params.get("returnurl")

    return (
        <>
            <div className="agile-estimator">
                <div className="estimator-confirmation">
                    <svg className="estimate-icon thank-you-icon" id="mail" xmlns="http://www.w3.org/2000/svg" width="74.585" height="74.585" viewBox="0 0 74.585 74.585"><path id="Path_84" data-name="Path 84" d="M73.7,26.648l-8-5.9V10.926A2.185,2.185,0,0,0,63.514,8.74H49.675L38.6.437a2.185,2.185,0,0,0-2.622,0L24.91,8.74H11.071a2.185,2.185,0,0,0-2.185,2.185v9.822l-8,5.9A2.185,2.185,0,0,0,0,28.406V72.4a2.185,2.185,0,0,0,2.185,2.185H72.4A2.185,2.185,0,0,0,74.585,72.4V28.406A2.185,2.185,0,0,0,73.7,26.648ZM70.215,29.51v6.284L65.7,38.042V26.178ZM37.292,4.916l5.1,3.824h-10.2Zm24.036,8.194V40.218L37.292,52.187,13.256,40.218V13.111ZM8.886,26.178V38.042L4.37,35.794V29.51ZM4.37,70.215V40.676L36.319,56.584a2.185,2.185,0,0,0,1.948,0L70.215,40.676V70.215Z" fill="#5d98ea"/><path id="Path_85" data-name="Path 85" d="M153.185,154.37h26.221a2.185,2.185,0,1,0,0-4.37H153.185a2.185,2.185,0,1,0,0,4.37Z" transform="translate(-129.003 -128.149)" fill="#5d98ea"/><path id="Path_86" data-name="Path 86" d="M153.185,214.37H166.3a2.185,2.185,0,1,0,0-4.37H153.185a2.185,2.185,0,1,0,0,4.37Z" transform="translate(-129.003 -179.409)" fill="#5d98ea"/></svg>
                    <h3>Great! Your results have been sent to the provided email address.</h3>
                    <p>Please check your inbox to view your estimate.</p>
                </div>
                <div className="button-row">
                    {returnUrl && 
                        <a href={returnUrl} className="button">
                            <svg className="button-icon" xmlns="http://www.w3.org/2000/svg" width="23.619" height="23.619" viewBox="0 0 23.619 23.619"><g id="Solid" transform="translate(-24 -24)"><path id="Path_374" data-name="Path 374" d="M138.374,146.455l-4.607-4.607a1.222,1.222,0,0,0-1.728,1.728l2.522,2.522h-13.34a1.222,1.222,0,0,0,0,2.443h13.34l-2.522,2.522a1.222,1.222,0,0,0,1.728,1.728l4.607-4.607A1.222,1.222,0,0,0,138.374,146.455Z" transform="translate(-91.113 -111.51)" fill="#fff"/><path id="Path_375" data-name="Path 375" d="M38.253,42.732a1.222,1.222,0,0,0-1.222,1.222v1.222H26.443V26.443H37.031v1.222a1.222,1.222,0,0,0,2.443,0V26.036A2.036,2.036,0,0,0,37.438,24h-11.4A2.036,2.036,0,0,0,24,26.036V45.583a2.036,2.036,0,0,0,2.036,2.036h11.4a2.036,2.036,0,0,0,2.036-2.036V43.954A1.222,1.222,0,0,0,38.253,42.732Z" fill="#fff"/></g></svg> 
                            Finish
                        </a>
                    }
                    <a href="*" className="button">
                        <svg className="button-icon" xmlns="http://www.w3.org/2000/svg" width="24.792" height="22.619" viewBox="0 0 24.792 22.619"><path id="refreshing" d="M24.265,23.862a1.184,1.184,0,0,0-1.642.328l-.035.053A11.309,11.309,0,1,0,11.309,36.386a1.578,1.578,0,1,0,0-3.157A8.153,8.153,0,1,1,19.4,24.1,1.183,1.183,0,0,0,17.5,25.5l2.344,3.515a1.425,1.425,0,0,0,2.408,0L24.593,25.5A1.184,1.184,0,0,0,24.265,23.862Z" transform="translate(0 -13.767)" fill="#fff"/></svg>
                        Start a New Estimate
                    </a>
                </div>
            </div>
        </>
  )
}

export default connect()(Confirmation);