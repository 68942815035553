import React from 'react'
import { Provider } from 'react-redux'
import store from '../src/store'
import Estimator from './components/layout/Estimator/Estimator'


function App() {
  return (
    <Provider store={store}>
        <Estimator />
    </Provider>
)
}

export default App;
