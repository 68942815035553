import { GET_QUESTION_INFO } from "../actions/types"

export const initialState = {
    questionInfo: [],
}

export default function(state = initialState, action) {
    switch(action.type) {
        case GET_QUESTION_INFO:
            return {
                ...state,
                questionInfo: action.payload
            }
        default:
            return state
    }
}