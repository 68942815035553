import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './reducers'
import { initialState as answerInitialState} from './reducers/answers'
import { initialState as questionInitialState} from './reducers/questionInfo'
import { initialState as sessionInitialState} from './reducers/session'
import { initialState as estimatorInitiatlState } from './reducers/estimator'

const initialState = {
    answers: answerInitialState,
    questionInfo: questionInitialState,
    session: sessionInitialState,
    estimator: estimatorInitiatlState,
}

const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(
        
    ),
});

export default store