import { 
    GET_SESSION_IP, 
    SEND_RESULTS, 
    SET_SESSION_BROWSER, 
    SET_SESSION_EMAIL, 
    SET_SESSION_FIRST_NAME, 
    SET_SESSION_LAST_NAME, 
    SET_SESSION_PHONE, 
    UPDATE_RESULTS 
} from "./types";
import store from '../store'
import axios from "axios";
import { url } from "../utils/constants";


export const getSessionIp = () => dispatch => {
    axios.get('https://geolocation-db.com/json/')
        .then(res => {
            dispatch({
                type: GET_SESSION_IP,
                payload: res.data
            })
        }).catch(err => console.log(err));
}


export const setSessionBrowser = (data) => {
    return async () => {
        store.dispatch({
            type: SET_SESSION_BROWSER,
            payload: data
        })
    }
}

export const setSessionFirstName = (data) => {
    return async () => {
        store.dispatch({
            type: SET_SESSION_FIRST_NAME,
            payload: data
        })
    }
}

export const setSessionLastName = (data) => {
    return async () => {
        store.dispatch({
            type: SET_SESSION_LAST_NAME,
            payload: data
        })
    }
}

export const setSessionEmail = (data) => {
    return async () => {
        store.dispatch({
            type: SET_SESSION_EMAIL,
            payload: data
        })
    }
}

export const setSessionPhone = (data) => {
    return async () => {
        store.dispatch({
            type: SET_SESSION_PHONE,
            payload: data
        })
    }
}

export const sendResults = (data) => dispatch => {
    axios.defaults.xsrfCookieName = 'csrftoken';
    axios.defaults.xsrfHeaderName = 'X-CSRFToken';
    axios.post(`${url}/api/sessions/`, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(res => {
        console.log("RESULTS")
        dispatch({
            type: SEND_RESULTS
        })
    }).catch(err => console.log(err));
}

export const updateResults = (data) => dispatch => {
    console.log('FINAL')
    console.log(data)
    axios.defaults.xsrfCookieName = 'csrftoken';
    axios.defaults.xsrfHeaderName = 'X-CSRFToken';
    axios.put(`${url}api/sessions/${data.sessionId}/`, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(res => {
        dispatch({
            type: UPDATE_RESULTS
        })
    }).catch(err => console.log(err));
} 
