import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { getQuestionInfo } from '../../../actions/questions';
import { getSessionIp, setSessionBrowser } from '../../../actions/session';
import AgileHeader from '../../page/AgileHeader';
import { browserName, browserVersion } from "react-device-detect";
import EstimatorContainer from '../../page/EstimatorContainer';

function Estimator() {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getQuestionInfo())
        dispatch(getSessionIp())
        dispatch(setSessionBrowser(
            {
                sessionBrowser: browserName,
                sessionBrowserVersion: browserVersion,
            }
        ))
    }, [])

    return (
        <div className='page'>
            <AgileHeader className="pt-5"/>
            <EstimatorContainer />
        </div>
            
    
        
  )
}

export default connect()(Estimator);