import { 
    SET_EXECUTIVE_SUMMARY, 
    SET_FINISHED_DESIGN,
    SET_LOGO_DESIGN,
    SET_APP_PURPOSE,
    SET_APP_SIZE,
    SET_COMPLIANCE,
    SET_DEVICES,
    SET_ROLE_INPUT,
    SET_PAYMENT_PROCESSING,
    SET_RECURRING_SUBSCRIPTION,
    SET_USER_SIGNUP,
    SET_MACHINE_LEARNING,
    SET_INTEGRATIONS,
    SET_BASIC_API_INTEGRATIONS,
    SET_ONGOING_SUPPORT,
    SET_REPORT_DASHBOARD,
    SET_ADMIN_CONSOLE,
    SET_MARKETING_WEBSITE,
    SET_HOSTING_ENVIRONMENT,
    SET_NEW_OR_EXISTING,
    SET_EXISTING_DB,
    SET_EXISTING_INTEGRATIONS,
    SET_BLOCKCHAIN,
    SET_TIMELINE,
    SET_CHOSEN_STACK,
    SET_EXISTING_CLOUD,
    SET_AGILE_EXP,
    SET_CLOUD_SUPPORT,
    SET_MARKETING_SERVICE,
    SET_FINAL_RESULTS,
} from '../actions/types.js'
import { clone } from 'ramda';

export const initialState = {
    executiveSummary: '',
    finishedDesign: '',
    logoBrandingDesign: '',
    appSize: '',
    appPurpose: '',
    appCompliance: '',
    devices: [],
    webComponent: '',
    paymentProcessing: '',
    recurringSubscriptions: [],
    userSignup: [],
    machineLearning: '',
    integrations: '',
    basicApiIntegrations: '',
    ongoingSupport: '',
    reportDashboard: '',
    adminConsole: '',
    marketingWebsite: [],
    marketingServices: [],
    hostingEnvironment: '',
    newOrExisting: '',
    existingDb: '',
    existingIntegrations: '',
    role: '',
    blockChain: '',
    timeline: '',
    chosenStack: '',
    existingCloud: '',
    agileExperienced: '',
    cloudSupport: '',
    finalResult: [],

}

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_EXECUTIVE_SUMMARY:
            return {
                ...state,
                executiveSummary: action.payload,
            }
        case SET_FINISHED_DESIGN:
            return {
                ...state,
                finishedDesign: action.payload,
            }
        case SET_LOGO_DESIGN:
            return {
                ...state,
                logoBrandingDesign: action.payload,
            }
        case SET_APP_PURPOSE:
            return {
                ...state,
                appPurpose: action.payload,
            }
        case SET_APP_SIZE:
            return {
                ...state,
                appSize: action.payload,
            }
        case SET_COMPLIANCE:
            return {
                ...state,
                appCompliance: action.payload,
            }
        case SET_DEVICES:
            return {
                ...state,
                devices: action.payload,
            }
        case SET_FINAL_RESULTS:
            let result = clone(state.finalResult)

            return {
                ...state,
                finalResult: [...result, action.payload],
            }
        case SET_PAYMENT_PROCESSING:
            return {
                ...state,
                paymentProcessing: action.payload,
            }
        case SET_RECURRING_SUBSCRIPTION:
            return {
                ...state,
                recurringSubscriptions: action.payload,
            }
        case SET_USER_SIGNUP:
            return {
                ...state,
                userSignup: action.payload,
            }
        case SET_MACHINE_LEARNING:
            return {
                ...state,
                machineLearning: action.payload,
            }
        case SET_INTEGRATIONS:
            return {
                ...state,
                integrations: action.payload,
            }
        case SET_BASIC_API_INTEGRATIONS:
            return {
                ...state,
                basicApiIntegrations: action.payload,
            }
        case SET_ONGOING_SUPPORT:
            return {
                ...state,
                ongoingSupport: action.payload,
            }
        case SET_REPORT_DASHBOARD:
            return {
                ...state,
                reportDashboard: action.payload,
            }
        case SET_ADMIN_CONSOLE:
            return {
                ...state,
                adminConsole: action.payload,
            }
        case SET_MARKETING_WEBSITE:
            return {
                ...state,
                marketingWebsite: action.payload,
            }
        case SET_MARKETING_SERVICE:
            return {
                ...state,
                marketingServices: action.payload,
            }
        case SET_HOSTING_ENVIRONMENT:
            return {
                ...state,
                hostingEnvironment: action.payload,
            }
        case SET_NEW_OR_EXISTING:
            return {
                ...state,
                newOrExisting: action.payload,
            }
        case SET_EXISTING_DB:
            return {
                ...state,
                existingDb: action.payload,
            }
        case SET_EXISTING_INTEGRATIONS:
            return {
                ...state,
                existingIntegrations: action.payload,
            }
        case SET_ROLE_INPUT:
            return {
                ...state,
                role: action.payload,
            }
        case SET_BLOCKCHAIN:
            return {
                ...state,
                blockChain: action.payload,
            }
        case SET_TIMELINE:
            return {
                ...state,
                timeline: action.payload,
            }
        case SET_CHOSEN_STACK:
            return {
                ...state,
                chosenStack: action.payload,
            }
        case SET_EXISTING_CLOUD:
            return {
                ...state,
                existingCloud: action.payload
            }
        case SET_AGILE_EXP:
            return {
                ...state,
                agileExperienced: action.payload,
            }
        case SET_CLOUD_SUPPORT:
            return {
                ...state,
                cloudSupport: action.payload,
            }
        default:
            return state
    }
}