import React from 'react'
import { connect, useSelector } from 'react-redux';
import Question from './Question';
import MultiSelect from './MultiSelect';


function EstimatorCarousel() {
    const questionInfo = useSelector((state) => state.questionInfo.questionInfo)

    return (
        <div id="questionCarousel" className="carousel slide estimator-slider" data-bs-ride="carousel" data-bs-interval="false" data-bs-wrap="false" data-bs-touch="false">
            <div className="carousel-inner">
                {questionInfo.map((question, index) => {
                    let activeCarousel = `carousel-item active`
                    let className = (index === 0 ? activeCarousel : "carousel-item")
                    if (question.display_question_flag) {
                        if (question.question_type === "radio"||question.question_type === "multiplier") {
                            return (
                                <div className={className} id={question.question_form_id} >
                                    <Question 
                                        questionInfo={question}
                                        index={index}
                                        count={questionInfo.length}
                                    />
                                </div>         
                            )
                        } else if (question.question_type === "checkbox") {
                            return (
                                <div className={className} id={question.question_form_id} >
                                    <MultiSelect 
                                        questionInfo={question} 
                                        index={index}
                                        count={questionInfo.length}
                                    />
                                </div>         
                            )
                        } 
                    }
                })}
            </div>
        </div>
    )
}

export default connect()(EstimatorCarousel);