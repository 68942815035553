import { 
    SET_ACTIVE_SLIDE_ID, 
    SET_ANSWERED_SLIDES, 
    SET_BACK_BUTTON_ACTIVE, 
    SET_CURR_SLIDE, 
    SET_EMAIL_ERROR, 
    SET_FIRST_NAME_ERROR, 
    SET_LAST_NAME_ERROR, 
    SET_NEXT_BUTTON_ACTIVE, 
    SET_PHONE_NUM_ERROR, 
    SET_PROGRESS, 
    SET_SUBMIT_COMPLETED, 
    SET_SUBMIT_ENABLED 
} from "./actionTypes"
import store from '../store'


export const setCurrSlide = (data) => {
    return async () => {
        store.dispatch({
            type: SET_CURR_SLIDE,
            payload: data
        })
    }
}

export const setProgress = (data) => {
    return async () => {
        store.dispatch({
            type: SET_PROGRESS,
            payload: data
        })
    }
}

export const setActiveSlideId = (data) => {
    return async () => {
        store.dispatch({
            type: SET_ACTIVE_SLIDE_ID,
            payload: data
        })
    }
}

export const setBackButtonActive = (data) => {
    return async () => {
        store.dispatch({
            type: SET_BACK_BUTTON_ACTIVE,
            payload: data
        })
    }
}

export const setNextButtonActive = (data) => {
    return async () => {
        store.dispatch({
            type: SET_NEXT_BUTTON_ACTIVE,
            payload: data
        })
    }
}

export const setAnsweredSlides = (data) => {
    return async () => {
        store.dispatch({
            type: SET_ANSWERED_SLIDES,
            payload: data
        })
    }
}

export const setSubmitEnabled = (data) => {
    return async () => {
        store.dispatch({
            type: SET_SUBMIT_ENABLED,
            payload: data
        })
    }
}

export const setSubmitCompleted = (data) => {
    return async () => {
        store.dispatch({
            type: SET_SUBMIT_COMPLETED,
            payload: data
        })
    }
}

export const setFirstNameError = (data) => {
    return async () => {
        store.dispatch({
            type: SET_FIRST_NAME_ERROR,
            payload: data
        })
    }
}

export const setLastNameError = (data) => {
    return async () => {
        store.dispatch({
            type: SET_LAST_NAME_ERROR,
            payload: data
        })
    }
}

export const setEmailError = (data) => {
    return async () => {
        store.dispatch({
            type: SET_EMAIL_ERROR,
            payload: data
        })
    }
}

export const setPhoneError = (data) => {
    return async () => {
        store.dispatch({
            type: SET_PHONE_NUM_ERROR,
            payload: data
        })
    }
}