import React, { useState } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux';
import { setDevices, setUserSignup, setRecurringSubscription, setMarketingServices } from '../../actions/answers';
import { getAnswerLogo } from "../assets/logos"
import { setAnsweredSlides, setNextButtonActive } from '../../actions/estimator'
import "./Style/style.css"

function MultiSelectQuestion(props) {
    const dispatch = useDispatch()
    const questionNum = props.index + 1
    const question = props.questionInfo
    const answeredSlides = useSelector((state) => state.estimator.answeredSlides)

    const devices = useSelector((state) => state.answers.devices)
    const userSignup = useSelector((state) => state.answers.userSignup)
    const recurringSubscriptions = useSelector((state) => state.answers.recurringSubscriptions)
    const marketingServices = useSelector((state) => state.answers.marketingServices)
    const [clicked, setClicked] = useState([]);


    const handleCheckboxChange = (e, answerText, questionFormId)=> {
        let newAnsweredSlides = answeredSlides.slice()
        if (!newAnsweredSlides.includes(questionFormId))
            newAnsweredSlides.push(questionFormId)
        dispatch(setAnsweredSlides(newAnsweredSlides))
        dispatch(setNextButtonActive(true))
        
        let resultArray
        let state
        if (questionFormId === 'devicesCheckbox') {
            resultArray = devices.slice()
            state = devices
        } 
        else if (questionFormId === 'userSignupCheckbox') {
            resultArray = userSignup.slice()
            state = userSignup
        } 
        else if (questionFormId === 'paymentSubscriptionCheckbox') {
            resultArray = recurringSubscriptions.slice()
            state = recurringSubscriptions
        } 
        else if (questionFormId === 'marketingServiceCheckbox') {
            resultArray = marketingServices.slice()
            state = marketingServices
        }

        let selectedOption
        let answers = question.answers

        answers.forEach((x, i) => {
            if (answerText === x.answer_text) selectedOption = x
        })

        if (!clicked.includes(answerText)) {
            resultArray.push(answerText)
        } else {
            resultArray = state.filter(item => item !== answerText)
        }

        if (question.required_flag && resultArray.length === 0) {
            dispatch(setNextButtonActive(false))
        }

        if (questionFormId === 'devicesCheckbox') {
            dispatch(setDevices(resultArray))
        } else if (questionFormId === 'userSignupCheckbox') {
            dispatch(setUserSignup(resultArray))
        } else if (questionFormId === 'paymentSubscriptionCheckbox') {
            dispatch(setRecurringSubscription(resultArray))
        } else if (questionFormId === 'marketingServiceCheckbox') {
            dispatch(setMarketingServices(resultArray))
        }
        
        setClicked(resultArray)
    }

    const question_text = question.required_flag ? "Select At Least One Option" : "Select Any That Apply"


    return (
        <div>
            <div className="estimator-progress">
                <div className="estimator-heading">
                    <div className="progress-count">
                        <div className="slide-number">
                            <h2>{('0'+questionNum).slice(-2)}</h2>
                            <h2 className="grey-text">/</h2>
                            <h2 className="grey-text">{props.count}</h2>
                        </div>
                        <h2 className="slide-title">{question.question_text}</h2>
                    </div>
                    <div>
                        <h6 className="slide-description">{question.question_description}</h6>
                    </div>
                </div>
                <p className="estimator-instruction light-blue-text">{question_text}.</p>
            </div>
            <div className="slider-questions">
                {question.answers.map((answer, index) => {
                    return (
                        <div className={"slide-question " + (clicked.includes(answer.answer_text) ? "question-selected" : "")} onClick={e => handleCheckboxChange(e, answer.answer_text, question.question_form_id)} >
                            <div className="slide-top">
                                {getAnswerLogo(question.question_form_id, answer.answer_text)}
                                <h3>{answer.answer_text}</h3>
                                <p>{answer.answer_description}</p>
                            </div>
                            {answer.display_estimate &&
                                <div className="slide-bottom">
                                    <div className={answer.value === 0 ? "slide-bottom-hidden" : ""}>
                                        <h6 className="slide-bottom-title">ESTIMATES</h6>
                                        <div className="slide-bottom-estimates">
                                            <div className="slide-bottom-estimate">
                                                <h6>INVESTMENT</h6>
                                                <h5>${answer.cost_low} - ${answer.cost_high}</h5>
                                            </div>
                                            <div className="slide-bottom-estimate">
                                                <h6>STORY POINTS</h6>
                                                <h5>{answer.value}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    )
                })}
            </div>
        </div>
        
    )
}

export default connect()(MultiSelectQuestion);