import { 
    SET_ACTIVE_SLIDE_ID, 
    SET_ANSWERED_SLIDES, 
    SET_BACK_BUTTON_ACTIVE, 
    SET_CURR_SLIDE, 
    SET_EMAIL_ERROR, 
    SET_FIRST_NAME_ERROR, 
    SET_LAST_NAME_ERROR, 
    SET_NEXT_BUTTON_ACTIVE, 
    SET_PHONE_NUM_ERROR, 
    SET_PROGRESS,
    SET_SUBMIT_COMPLETED,
    SET_SUBMIT_ENABLED 
} from "../actions/actionTypes"

export const initialState = {
    currSlide: 1,
    progress: 0,
    activeSlideId: '',
    backButtonActive: false,
    nextButtonActive: false,
    answeredSlides: [],
    submitEnabled: false,
    submitCompleted: false,
    firstNameError: false,
    lastNameError: false,
    emailError: false,
    phoneError: false,
}

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_CURR_SLIDE:
            return {
                ...state,
                currSlide: action.payload
            }
        case SET_PROGRESS:
            return {
                ...state,
                progress: action.payload
            }
        case SET_ACTIVE_SLIDE_ID:
            return {
                ...state,
                activeSlideId: action.payload
            }
        case SET_BACK_BUTTON_ACTIVE:
            return {
                ...state,
                backButtonActive: action.payload
            }
        case SET_NEXT_BUTTON_ACTIVE:
            return {
                ...state,
                nextButtonActive: action.payload
            }
        case SET_ANSWERED_SLIDES:
            return {
                ...state,
                answeredSlides: action.payload
            }
        case SET_SUBMIT_ENABLED:
            return {
                ...state,
                submitEnabled: action.payload
            }
        case SET_SUBMIT_COMPLETED:
            return {
                ...state,
                submitCompleted: action.payload
            }
        case SET_FIRST_NAME_ERROR:
            return {
                ...state,
                firstNameError: action.payload
            }
        case SET_LAST_NAME_ERROR:
            return {
                ...state,
                lastNameError: action.payload
            }
        case SET_PHONE_NUM_ERROR:
            return {
                ...state,
                phoneError: action.payload
            }
        case SET_EMAIL_ERROR:
            return {
                ...state,
                emailError: action.payload
            }
        default:
            return state
    }
}
