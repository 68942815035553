import { 
    SET_EXECUTIVE_SUMMARY,
    SET_FINISHED_DESIGN, 
    SET_APP_SIZE,
    SET_COMPLIANCE,
    SET_DEVICES,
    SET_ROLE_INPUT,
    SET_PAYMENT_PROCESSING,
    SET_RECURRING_SUBSCRIPTION,
    SET_USER_SIGNUP,
    SET_MACHINE_LEARNING,
    SET_INTEGRATIONS,
    SET_BASIC_API_INTEGRATIONS,
    SET_ONGOING_SUPPORT,
    SET_REPORT_DASHBOARD,
    SET_ADMIN_CONSOLE,
    SET_MARKETING_WEBSITE,
    SET_HOSTING_ENVIRONMENT,
    SET_EXISTING_DB,
    SET_NEW_OR_EXISTING,
    SET_EXISTING_INTEGRATIONS,
    SET_BLOCKCHAIN,
    SET_TIMELINE,
    SET_CHOSEN_STACK,
    SET_EXISTING_CLOUD,
    SET_AGILE_EXP,
    SET_CLOUD_SUPPORT,
    SET_MARKETING_SERVICE,
    SET_FINAL_RESULTS
} from "./types";
import store from '../store'
import 'regenerator-runtime/runtime'


export const setExecutiveSummary = (data) => {
    return async () => {
        store.dispatch({
            type: SET_EXECUTIVE_SUMMARY,
            payload: data
        })
    }
}

export const setRoleInput = (data) => {
    return async () => {
        store.dispatch({
            type: SET_ROLE_INPUT,
            payload: data
        })
    }
}

export const setFinishedDesign = (data) => {
    return async () => {
        store.dispatch({
            type: SET_FINISHED_DESIGN,
            payload: data
        })
    }
}

export const setAppSize = (data) => {
    return async () => {
        store.dispatch({
            type: SET_APP_SIZE,
            payload: data
        })
    }
}


export const setAppCompliance = (data) => {
    return async () => {
        store.dispatch({
            type: SET_COMPLIANCE,
            payload: data
        })
    }
}

export const setDevices = (data) => {
    return async () => {
        store.dispatch({
            type: SET_DEVICES,
            payload: data
        })
    }
}

export const setFinalResults = (data) => {
    return async () => {
        store.dispatch({
            type: SET_FINAL_RESULTS,
            payload: data
        })
    }
}

export const setPaymentProcessing = (data) => {
    return async () => {
        store.dispatch({
            type: SET_PAYMENT_PROCESSING,
            payload: data
        })
    }
}

export const setRecurringSubscription = (data) => {
    return async () => {
        store.dispatch({
            type: SET_RECURRING_SUBSCRIPTION,
            payload: data
        })
    }
}

export const setUserSignup = (data) => {
    return async () => {
        store.dispatch({
            type: SET_USER_SIGNUP,
            payload: data
        })
    }
}

export const setMachineLearning = (data) => {
    return async () => {
        store.dispatch({
            type: SET_MACHINE_LEARNING,
            payload: data
        })
    }
}

export const setIntegrations = (data) => {
    return async () => {
        store.dispatch({
            type: SET_INTEGRATIONS,
            payload: data
        })
    }
}

export const setBasicApiIntegrations = (data) => {
    return async () => {
        store.dispatch({
            type: SET_BASIC_API_INTEGRATIONS,
            payload: data
        })
    }
}

export const setOngoingSupport = (data) => {
    return async () => {
        store.dispatch({
            type: SET_ONGOING_SUPPORT,
            payload: data
        })
    }
}

export const setReportDashboard = (data) => {
    return async () => {
        store.dispatch({
            type: SET_REPORT_DASHBOARD,
            payload: data
        })
    }
}

export const setAdminConsole = (data) => {
    return async () => {
        store.dispatch({
            type: SET_ADMIN_CONSOLE,
            payload: data
        })
    }
}

export const setMarketingWebsite = (data) => {
    return async () => {
        store.dispatch({
            type: SET_MARKETING_WEBSITE,
            payload: data
        })
    }
}

export const setMarketingServices = (data) => {
    return async () => {
        store.dispatch({
            type: SET_MARKETING_SERVICE,
            payload: data
        })
    }
}

export const setHostingEnvironment = (data) => {
    return async () => {
        store.dispatch({
            type: SET_HOSTING_ENVIRONMENT,
            payload: data
        })
    }
}

export const setNewOrExisting = (data) => {
    return async () => {
        store.dispatch({
            type: SET_NEW_OR_EXISTING,
            payload: data
        })
    }
}

export const setExistingDb = (data) => {
    return async () => {
        store.dispatch({
            type: SET_EXISTING_DB,
            payload: data
        })
    }
}

export const setExistingIntegrations = (data) => {
    return async () => {
        store.dispatch({
            type: SET_EXISTING_INTEGRATIONS,
            payload: data
        })
    }
}

export const setBlockchain = (data) => {
    return async () => {
        store.dispatch({
            type: SET_BLOCKCHAIN,
            payload: data
        })
    }
}

export const setTimeline = (data) => {
    return async () => {
        store.dispatch({
            type: SET_TIMELINE,
            payload: data
        })
    }
}

export const setChosenStack = (data) => {
    return async () => {
        store.dispatch({
            type: SET_CHOSEN_STACK,
            payload: data
        })
    }
}

export const setExistingCloud = (data) => {
    return async () => {
        store.dispatch({
            type: SET_EXISTING_CLOUD,
            payload: data
        })
    }
}

export const setAgileExp = (data) => {
    return async () => {
        store.dispatch({
            type: SET_AGILE_EXP,
            payload: data
        })
    }
}

export const setCloudSupport = (data) => {
    return async () => {
        store.dispatch({
            type: SET_CLOUD_SUPPORT,
            payload: data
        })
    }
}