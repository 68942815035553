export const SET_CURR_SLIDE = "SET_CURR_SLIDE"
export const SET_PROGRESS = "SET_PROGRESS"
export const SET_ACTIVE_SLIDE_ID = "SET_ACTIVE_SLIDE_ID"
export const SET_BACK_BUTTON_ACTIVE = "SET_BACK_BUTTON_ACTIVE"
export const SET_NEXT_BUTTON_ACTIVE = "SET_NEXT_BUTTON_ACTIVE"
export const SET_ANSWERED_SLIDES = "SET_ANSWERED_SLIDES"
export const SET_SUBMIT_ENABLED = "SET_ENABLE_SUBMIT"
export const SET_SUBMIT_COMPLETED = "SET_SUBMIT_COMPLETED"
export const SET_FIRST_NAME_ERROR = "SET_FIRST_NAME_ERROR"
export const SET_LAST_NAME_ERROR = "SET_LAST_NAME_ERROR"
export const SET_EMAIL_ERROR = "SET_EMAIL_ERROR"
export const SET_PHONE_NUM_ERROR = "SET_PHONE_NUM_ERROR"