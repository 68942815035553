
export const GET_QUESTION_INFO = 'GET_QUESTION_INFO'
export const SET_EXECUTIVE_SUMMARY = 'SET_EXECUTIVE_SUMMARY'
export const SET_FINISHED_DESIGN = 'SET_FINISHED_DESIGN'
export const SET_LOGO_DESIGN = 'SET_LOGO_DESIGN'
export const SET_APP_SIZE = 'SET_APP_SIZE'
export const SET_APP_PURPOSE = 'SET_APP_PURPOSE'
export const SET_COMPLIANCE = 'SET_COMPLIANCE'
export const SET_DEVICES = "SET_DEVICES"
export const SET_PAYMENT_PROCESSING = "SET_PAYMENT_PROCESSING"
export const SET_RECURRING_SUBSCRIPTION = "SET_RECURRING_SUBSCRIPTION"
export const SET_USER_SIGNUP = "SET_USER_SIGNUP"
export const SET_MACHINE_LEARNING = "SET_MACHINE_LEARNING"
export const SET_INTEGRATIONS = "SET_INTEGRATIONS"
export const SET_BASIC_API_INTEGRATIONS = "SET_BASIC_API_INTEGRATIONS"
export const SET_ONGOING_SUPPORT = "SET_ONGOING_SUPPORT"
export const SET_REPORT_DASHBOARD = "SET_REPORT_DASHBOARD"
export const SET_ADMIN_CONSOLE = "SET_ADMIN_CONSOLE"
export const SET_MARKETING_WEBSITE = "SET_MARKETING_WEBSITE"
export const SET_MARKETING_SERVICE = "SET_MARKETING_SERVICE"
export const SET_HOSTING_ENVIRONMENT = "SET_HOSTING_ENVIRONMENT"
export const SET_NEW_OR_EXISTING = "SET_NEW_OR_EXISTING"
export const SET_EXISTING_DB = "SET_EXISTING_DB"
export const SET_EXISTING_INTEGRATIONS = "SET_EXISTING_INTEGRATIONS"
export const SET_ROLE_INPUT = "SET_ROLE_INPUT"
export const SET_BLOCKCHAIN = "SET_BLOCKCHAIN"
export const SET_TIMELINE = "SET_TIMELINE"
export const SET_CHOSEN_STACK = "SET_CHOSEN_STACK"
export const SET_EXISTING_CLOUD = "SET_EXISTING_CLOUD"
export const SET_AGILE_EXP = "SET_AGILE_EXP"
export const SET_CLOUD_SUPPORT = "SET_CLOUD_SUPPORT"

export const GET_SESSION_IP = "GET_SESSION_IP"
export const SET_SESSION_BROWSER = "SET_SESSION_BROWSER"
export const SET_SESSION_FIRST_NAME = "SET_SESSION_FIRST_NAME"
export const SET_SESSION_LAST_NAME = "SET_SESSION_LAST_NAME"
export const SET_SESSION_EMAIL = "SET_SESSION_EMAIL"
export const SET_SESSION_PHONE = "SET_SESSION_PHONE"
export const SET_SESSION_ACCEPT = "SET_SESSION_ACCEPT"

export const SEND_RESULTS = "SEND_RESULTS"
export const UPDATE_RESULTS = "UPDATE_RESULTS"

export const SET_FINAL_RESULTS = "SET_FINAL_RESULTS"