import { GET_QUESTION_INFO } from "./types";
import axios from "axios";
import { url } from "../utils/constants";

export const getQuestionInfo = () => dispatch => {
    axios.get(`${url}/api/questions/`)
        .then(res => {
            dispatch({
                type: GET_QUESTION_INFO,
                payload: res.data
            })
        }).catch(err => console.log(err));
} 