import React from 'react'
import { connect, useSelector } from 'react-redux';
import './Style/style.css'
import AgileLogo from './Style/assets/images/agile-estimator-logo-1.png'

function AgileHeader() {

    const progress = useSelector((state) => state.estimator.progress)
    
    let styleProgress = `${progress}%`
    let style = {width: styleProgress}

    return (
        <div className="agile-header-container">
            <div className="container">
                <div className="agile-header">
                    <div className="agile-header-title">
                        <img src={AgileLogo} alt="Augusto Digital Agile Estimator Logo" width={300} />
                    </div>
                    <div className="agile-header-progress">
                        <div className="quiz-results-progressbar">
                            <div className="progressbar-inner" style={style}>
                                <div className="progressbar-inner-label quiz-percent-zero"><span class="quiz-percent">{progress}</span>%</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect()(AgileHeader);